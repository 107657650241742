
form[name='update-feed'] .socials {
  margin-bottom: 10px;
}



.fa-check.active[data-v-aea6b40e], .fa-user-circle-o.active[data-v-aea6b40e] {
  color:green;
}
.fa-check.disabled[data-v-aea6b40e], .fa-user-circle-o.disabled[data-v-aea6b40e] {
  color:red;
}



